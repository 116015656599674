import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../atoms/breadcrumb/breadcrumb'
import Footer from '../footer/footer'
import Header from '../header/header'
import ShopButton from '../../atoms/shop-button/shop-button'
import './layout.scss'

const Layout = props => {
    const { children, breadcrumb, isShopButton = true } = props

    const [topMenuActive, settopMenuActive] = useState(true)
    const [currentScroll, setcurrentScroll] = useState(0)

    const handleScroll = () => {
        let scrollTop = window.pageYOffset;
        setcurrentScroll(scrollTop);

        if (currentScroll <= scrollTop) {
            settopMenuActive(false);
        } else {
            settopMenuActive(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    const EmployeeRedirect = process.env.ENVIRONMENT ?
        "https://www.grupofamilia.com.co/es/Paginas/empleados.aspx" :
        "https://qas.familia.com.co/es/Paginas/empleados.aspx"
    const ClientRedirect = process.env.ENVIRONMENT ?
        "https://www.grupofamilia.com.co/clientes/es/Paginas/default.aspx" :
        "https://qas.familia.com.co/es/Paginas/autenticacion-de-usuarios.aspx?Source=%2Fclientes%2Fes"
    const SupplierRedirect = process.env.ENVIRONMENT ?
        "https://www.grupofamilia.com.co/Proveedores/es/Paginas/default.aspx" :
        "https://qas.familia.com.co/es/Paginas/autenticacion-de-usuarios.aspx?Source=%2FProveedores%2Fes"

    return (
        <>
            <Header
                topMenuActive={topMenuActive}
                EmployeeRedirect={EmployeeRedirect}
                ClientRedirect={ClientRedirect}
                SupplierRedirect={SupplierRedirect}
            />
            <div>
                <main
                    className={"f-main-container " + (topMenuActive ? 'f-top-menu-active' : '')}
                >
                    {breadcrumb && <Breadcrumb
                        topMenuActive={topMenuActive}
                    />}
                    {children}
                </main>
                {isShopButton && <ShopButton />}
                <Footer
                    EmployeeRedirect={EmployeeRedirect}
                    ClientRedirect={ClientRedirect}
                    SupplierRedirect={SupplierRedirect}
                />
            </div>
        </>
    )
}

export default Layout
