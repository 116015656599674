import React, { useState } from 'react'
import './header.scss'
import { useStaticQuery, graphql } from 'gatsby'
import siteUrl from '../../../utils/siteUrl'
import Button from '../../atoms/button/button'

const Header = props => {
    const { topMenuActive, EmployeeRedirect, ClientRedirect, SupplierRedirect } = props

    const { contentfulExtranetTransversal } = useStaticQuery(
        graphql`
          query HEADER_QUERY{
            contentfulExtranetTransversal {
                logo {
                  file {
                    url
                  }
                }
                header {
                  opcionesDelMenu {
                    redireccionDeLaOpcion
                    nombreDeLaOpcion
                  }
                }
                menu {
                  opcionesDelMenu {
                    nombreDeLaOpcion
                    redireccionDeLaOpcion
                  }
                }
                footerInformacionLegal
                footerInformacionLegalDerechosReservados
                footerPoliticaDePrivacidad
                footerRedireccionPolticaDePrivacidad
                footerRedireccionTerminosYCondiciones
                footerTerminosYCondiciones
                redesSociales {
                    redireccionDeLaCategoria
                    nombreDeLaCategoria
                }
              }
          }
        `
    )

    const { logo, header, menu, footerInformacionLegal,
        footerInformacionLegalDerechosReservados,
        footerPoliticaDePrivacidad,
        footerRedireccionPolticaDePrivacidad,
        footerRedireccionTerminosYCondiciones,
        footerTerminosYCondiciones, redesSociales } = contentfulExtranetTransversal

    const [activeHoverMenu, setactiveHoverMenu] = useState(-1)
    const [mobileMenuActive, setmobileMenuActive] = useState(false)

    const getSocial = name => {
        return redesSociales.filter(e => name === e.nombreDeLaCategoria)[0]
    }

    const datalayerHeader = (nameSeccion) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "ga_event",
        category: "Menú",
        action: "Click",
        label: nameSeccion,
        value: "",
      })
    }

    return (
        <header className="f-header">
            <div className={"f-header-top " + (topMenuActive ? 'active' : '')}>
                <div className="f-header-top-nav">
                    <a className="f-header-top-nav-item f-icon-social" href={getSocial('Twitter').redireccionDeLaCategoria} target='_blank' rel="noopener noreferrer" target="_blank">
                        <i className="f-icon fab fa-twitter"></i>
                    </a>
                    <a className="f-header-top-nav-item f-icon-social" href={getSocial('Linkedin').redireccionDeLaCategoria} target='_blank' rel="noopener noreferrer" target="_blank">
                        <i className="f-icon fab fa-linkedin-in"></i>
                    </a>
                    <a className="f-header-top-nav-item f-icon-social" href={getSocial('Youtube').redireccionDeLaCategoria} target='_blank' rel="noopener noreferrer" target="_blank">
                        <i className="f-icon fab fa-youtube"></i>
                    </a>
                    {header.opcionesDelMenu.map((item, index) => {
                        return (
                            <a className="f-header-top-nav-item" href={siteUrl + '/' + item.redireccionDeLaOpcion} key={index}>
                                {item.nombreDeLaOpcion}
                            </a>
                        )
                    })}
                    <a className="f-header-top-nav-item" href={siteUrl + '/buscador/'}>
                        Buscar <i className="f-icon fal fa-search"></i>
                    </a>
                </div>
            </div>
            <nav className={"f-header-bottom " + (mobileMenuActive ? 'f-menu-mobile-active' : '')}>
                <a className={"f-header-bottom-logo " + (topMenuActive ? '' : 'top-no-active')} href={siteUrl}>
                    <img
                        src={logo[mobileMenuActive ? 2 : 0].file.url}
                        className="f-header-bottom-logo-img"
                        alt="Logo Grupo Familia"
                    />
                </a>
                <div className="f-header-bottom-nav">
                    {menu.opcionesDelMenu.map((item, index) => {
                        return (
                            <div className="f-header-bottom-nav-item" onMouseEnter={() => setactiveHoverMenu(index)} onMouseLeave={() => setactiveHoverMenu(-1)} key={index}>
                                <a className="f-header-bottom-nav-item-redirect" href={siteUrl + '/' + item.redireccionDeLaOpcion} onClick={()=>datalayerHeader(item.nombreDeLaOpcion)}>
                                    {item.nombreDeLaOpcion}
                                </a>
                            </div>
                        )
                    })}
                </div>
                <div className="f-header-bottom-transactional"
                    onMouseEnter={() => setactiveHoverMenu("zone")}
                    onMouseLeave={() => setactiveHoverMenu(-1)}
                    onBlur={() => setactiveHoverMenu(-1)}
                >
                    <Button
                        title={
                            <>Zona Transaccional <i className="f-icon fal fa-chevron-down "></i></>
                        }
                        onClick={() => setactiveHoverMenu("zone")}
                        size='xsmall'
                    />

                    {activeHoverMenu === "zone" &&
                        <div className="f-header-bottom-nav-item-dropdown transactional">
                            <a
                                className="f-header-bottom-nav-item-dropdown-item"
                                target="_blank"
                                href={EmployeeRedirect}
                            >
                                Empleados
                                <i className="f-icon fal fa-long-arrow-right"></i>
                            </a>
                            <a
                                className="f-header-bottom-nav-item-dropdown-item"
                                target="_blank"
                                href={ClientRedirect}
                            >
                                Clientes
                                <i className="f-icon fal fa-long-arrow-right"></i>
                            </a>
                            <a
                                className="f-header-bottom-nav-item-dropdown-item"
                                target="_blank"
                                href={SupplierRedirect}
                            >
                                Proveedores
                                <i className="f-icon fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    }
                </div>

                <div className={"f-header-mobile " + (mobileMenuActive ? 'f-menu-mobile-active' : '')}>
                    <div className="f-header-mobile-icon-container" onClick={() => {
                        window.open(siteUrl + '/buscador', '_self')
                    }}>
                        <i className="f-icon fal fa-search"></i>
                    </div>
                    {!mobileMenuActive &&
                        <div className="f-header-mobile-icon-container" onClick={() => setmobileMenuActive(true)}>
                            <i className="f-icon fal fa-bars"></i>
                        </div>
                    }
                    {mobileMenuActive &&
                        <div className="f-header-mobile-icon-container close" onClick={() => setmobileMenuActive(false)}>
                            <i className="f-icon fal fa-times"></i>
                        </div>
                    }
                </div>
                {mobileMenuActive &&
                    <div className="f-menu-mobile-fixed">
                        <div className="f-menu-mobile-main-options-container">
                            {menu.opcionesDelMenu.map((item, index) => {
                                return (
                                    <div className="f-menu-mobile-main-options" onClick={() => setactiveHoverMenu(activeHoverMenu === index ? -1 : index)} key={index}>

                                        {item.opcionesSecundarias
                                            ? (
                                                <div className="f-menu-mobile-main-options-redirect">
                                                    {item.nombreDeLaOpcion}
                                                    {item.opcionesSecundarias && <i className={"f-icon fal fa-chevron-down " + (activeHoverMenu === index ? 'active' : '')}></i>}
                                                </div>
                                            )
                                            : (
                                                <a className="f-menu-mobile-main-options-redirect" href={siteUrl + '/' + item.redireccionDeLaOpcion}>
                                                    {item.nombreDeLaOpcion}
                                                    {item.opcionesSecundarias && <i className={"f-icon fal fa-chevron-down " + (activeHoverMenu === index ? 'active' : '')}></i>}
                                                </a>
                                            )}


                                        {item.opcionesSecundarias && activeHoverMenu === index &&
                                            <div className="f-menu-mobile-main-options-dropdown">
                                                {item.opcionesSecundarias.map((childItem, childIndex) => {
                                                    return (
                                                        <a className="f-menu-mobile-main-options-dropdown-item" key={childIndex} href={siteUrl + '/' + item.redireccionDeLaOpcion + childItem.redireccionDeLaOpcion}>
                                                            {childItem.nombreDeLaOpcion}
                                                        </a>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        <div className="f-menu-mobile-main-transactional"
                            onMouseLeave={() => setactiveHoverMenu(-1)}
                        >
                            <Button
                                title={
                                    <>Zona Transaccional <i className="f-icon fal fa-chevron-down "></i></>
                                }
                                size='small'
                                onClick={() => setactiveHoverMenu((v) => v === "zone" ? -1 : "zone")}
                            />

                            {activeHoverMenu === "zone" &&
                                <div className="f-header-bottom-nav-item-dropdown transactional">
                                    <a
                                        className="f-header-bottom-nav-item-dropdown-item"
                                        target="_blank"
                                        href={EmployeeRedirect}
                                    >
                                        Empleados
                                        <i className="f-icon fal fa-long-arrow-right"></i>
                                    </a>
                                    <a
                                        className="f-header-bottom-nav-item-dropdown-item"
                                        target="_blank"
                                        href={ClientRedirect}
                                    >
                                        Clientes
                                        <i className="f-icon fal fa-long-arrow-right"></i>
                                    </a>
                                    <a
                                        className="f-header-bottom-nav-item-dropdown-item"
                                        target="_blank"
                                        href={SupplierRedirect}
                                    >
                                        Proveedores
                                        <i className="f-icon fal fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            }
                        </div>


                        <div className="f-menu-mobile-main-other-redirects">
                            {header.opcionesDelMenu.map((item, index) => {
                                return (
                                    <a className="f-menu-mobile-nav-item" href={siteUrl + '/' + item.redireccionDeLaOpcion} key={index}>
                                        {item.nombreDeLaOpcion}
                                    </a>
                                )
                            })}
                            <a className="f-menu-mobile-nav-item" href={siteUrl + '/contactanos/'}>
                                Contáctanos
                            </a>
                        </div>
                        <div className="f-menu-mobile-name">
                            <div className="f-menu-mobile-name-item">{footerInformacionLegal}</div>
                            <div className="f-menu-mobile-name-item">{footerInformacionLegalDerechosReservados}</div>
                            <a className="f-menu-mobile-name-item" href={siteUrl + '/' + footerRedireccionTerminosYCondiciones}>{footerTerminosYCondiciones}</a>
                            <a className="f-menu-mobile-name-item" href={siteUrl + '/' + footerRedireccionPolticaDePrivacidad}>{footerPoliticaDePrivacidad}</a>
                        </div>
                    </div>
                }
            </nav>
        </header>
    )
}

export default Header
