import React, { useEffect, useState } from "react"
import siteUrl from "../../../utils/siteUrl"
import "./breadcrumb.scss"

const Breadcrumb = props => {
    const { topMenuActive, outOfBanner = false } = props

    const [path, setpath] = useState("")

    useEffect(() => {
        setpath(window.location.pathname)
    }, [])

    const camelize = (str = '') => {
        return (str.charAt(0).toUpperCase() + str.slice(1)).replace(/\s+/g, "").replace(/-/g, " ")
    }

    const urlTransform = () => {
        var elements = path.split("/")
        elements.shift()
        if (elements[elements.length - 1] === "") elements.pop()

        return elements
    }

    return (

        <div
            className={"f-breadcrumb-container " + (topMenuActive ? "" : "no-top-menu ") + (outOfBanner ? "out-of-banner " : "")}>
            <div className="f-breadcrumb-element">
                <a className="f-link f-link-breadcrumb" href={siteUrl + "/"}>Inicio</a>
            </div>

            {urlTransform().map((element, index) => {

                var fullPath = ""
                urlTransform().map((elementPath, indexPath) => indexPath <= index ? fullPath += elementPath + "/" : "")

                if (index < urlTransform().length - 1) {

                    return (
                        <div key={index} className="f-breadcrumb-element">
                            <span className="f-breadcrumb-separator">/</span>
                            <a className="f-link f-link-breadcrumb"
                                href={siteUrl + "/" + fullPath}>{camelize(element)}</a>
                        </div>

                    )

                } else {
                    return (
                        <div key={index} className="f-breadcrumb-element">
                            <span className="f-breadcrumb-separator">/</span>
                            <span className="f-current-site">{camelize(element)}</span>
                        </div>

                    )
                }
            })}
        </div>
    )
}

export default Breadcrumb
