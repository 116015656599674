import React from 'react'
import { graphql, useStaticQuery, } from "gatsby"
import './shop-button.scss'

const ShopButton = () => {
    const { contentfulExtranetTransversal: { imagenBotonDeCompra, redireccionBotonDeCompra } } = useStaticQuery(graphql`
        query ShopButton {
            contentfulExtranetTransversal {
            redireccionBotonDeCompra
            imagenBotonDeCompra {
                file {
                url
                }
            }
            }
        }
    `)

    const datalayerButtonShop = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "ga_event",
        category: "Tienda Online",
        action: "Click",
        label: "Cuídate en Familia",
        value: "",
      })
    }

    return (
        <a className="f-shop-button" href={redireccionBotonDeCompra} target="_blank" onClick={datalayerButtonShop}>
            <img src={imagenBotonDeCompra.file?.url} alt="shop button" />
        </a>
    )
}

export default ShopButton
