import React from 'react'
import './button.scss'


const Button = props => {
    const { color = 'blue', title, onClick, icon, iconPosition = 'right', size } = props
    return (
        <div className={"f-btn-container " + color + " " + size} >
            <button
                className={'f-btn ' +
                    (icon && iconPosition === 'left' ?
                        ' f-btn-icon-left'
                        :
                        icon && iconPosition === 'right' ?
                            ' f-btn-icon-right'
                            :
                            ''
                    )
                }
                onClick={onClick}
                {...props}
            >
                {icon && iconPosition === "right" && icon}
                {title}
                {icon && iconPosition === "left" && icon}
            </button>

        </div>
    )
}

export default Button
