import React from "react"
import "./footer.scss"
import { useStaticQuery, graphql } from "gatsby"
import siteUrl from "../../../utils/siteUrl"

const Footer = props => {
  const { contentfulExtranetTransversal } = useStaticQuery(
    graphql`
      query FOOTER_QUERY {
        contentfulExtranetTransversal {
          logo {
            file {
              url
            }
          }
          footerPrimerNivel {
            opcionesDelMenu {
              nombreDeLaOpcion
              redireccionDeLaOpcion
            }
          }
          footerSegundoNivel {
            opcionesDelMenu {
              nombreDeLaOpcion
              redireccionDeLaOpcion
            }
          }
          footerInformacionLegal
          footerInformacionLegalDerechosReservados
          footerPoliticaDePrivacidad
          footerRedireccionPolticaDePrivacidad
          footerRedireccionTerminosYCondiciones
          footerTerminosYCondiciones
        }
      }
    `
  )

  const {
    footerPrimerNivel,
    footerSegundoNivel,
    logo,
    footerInformacionLegal,
    footerInformacionLegalDerechosReservados,
    footerPoliticaDePrivacidad,
    footerRedireccionPolticaDePrivacidad,
    footerRedireccionTerminosYCondiciones,
    footerTerminosYCondiciones,
  } = contentfulExtranetTransversal

  const { EmployeeRedirect, ClientRedirect, SupplierRedirect } = props

  const datalayerFooter = nameSeccion => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "ga_event",
      category: "Footer",
      action: "Click",
      label: nameSeccion,
      value: "",
    })
  }

  return (
    <footer className="f-footer">
      <div className="f-footer-info-container">
        <div className="f-footer-top">
          <div className="f-footer-nav">
            <div className="f-footer-nav-top">
              {footerPrimerNivel.opcionesDelMenu.map((item, index) => {
                return (
                  <a
                    className="f-footer-nav-top-item"
                    key={index}
                    href={siteUrl + "/" + item.redireccionDeLaOpcion}
                    onClick={() => datalayerFooter(item.nombreDeLaOpcion)}
                  >
                    {item.nombreDeLaOpcion}
                  </a>
                )
              })}
            </div>
            <div className="f-footer-v-separator" />
            <div className="f-footer-nav-bottom">
              {footerSegundoNivel.opcionesDelMenu.map((item, index) => {
                const isExternalLink = item.redireccionDeLaOpcion.includes(
                  "https://assets"
                )
                const hrefValue = isExternalLink
                  ? item.redireccionDeLaOpcion
                  : `${siteUrl}/${item.redireccionDeLaOpcion}`
                const targetValue = isExternalLink ? "_blank" : undefined

                return (
                  <a
                    className="f-footer-nav-bottom-item"
                    key={index}
                    href={hrefValue}
                    target={targetValue}
                    onClick={() => datalayerFooter(item.nombreDeLaOpcion)}
                    rel={targetValue ? "noopener noreferrer" : undefined}
                  >
                    {item.nombreDeLaOpcion}
                  </a>
                )
              })}
            </div>
          </div>
          <div className="f-footer-h-separator" />
          <div className="f-footer-contact">
            {/* <div className="f-footer-contact-title">
                            	Speak up
                        </div>

                        <div className="f-footer-contact-line">
                            <div className="f-footer-contact-line-icon">
                                <i className="f-icon fal fa-phone" />
                            </div>
                            <div className="f-footer-contact-line-right">
                                <div className="f-footer-contact-line-right-colombia">
                                    Colombia: 01-8009-440-692
                                </div>
                                <a className="f-footer-contact-line-right-international" href='/contactanos/' onClick={()=>datalayerFooter("Internacional")}>
                                    {"Internacional >"}
                                </a>
                            </div>
                        </div>

                        <div className="f-footer-contact-form-redirect">
                            <div className="f-footer-contact-form-redirect-icon">
                                <i className="f-icon fal fa-desktop"></i>
                            </div>
                            <div className="f-footer-contact-form-redirect-web">

                                <div className="f-footer-contact-form-redirect-web-report">
                                    Reporte vía web
                                </div>
                                <a className="f-footer-contact-form-redirect-web-form" href="https://www.speakupfeedback.eu/web/essity/co" target="_blank" onClick={()=>datalayerFooter("Ir al formulario")}>
                                    {"Ir al formulario >"}
                                </a>
                            </div>
                        </div> */}

            <div className="f-footer-contact-title">Zona Transaccional</div>
            <div className="f-footer-contact-transactional f-footer-contact-transactional--zone">
              <div className="f-footer-contact-transactional-icon">
                <i className="f-icon fal fa-desktop"></i>
              </div>
              <div className="f-footer-contact-transactional-web">
                <a
                  className="f-footer-contact-transactional-web-form"
                  href={EmployeeRedirect}
                  target="_blank"
                  onClick={() => datalayerFooter("Empleados")}
                >
                  Empleados
                </a>
              </div>
            </div>
            <div className="f-footer-contact-transactional f-footer-contact-transactional--zone">
              <div className="f-footer-contact-transactional-icon">
                <i className="f-icon fal fa-desktop"></i>
              </div>
              <div className="f-footer-contact-transactional-web">
                <a
                  className="f-footer-contact-transactional-web-form"
                  href={ClientRedirect}
                  target="_blank"
                  onClick={() => datalayerFooter("Clientes")}
                >
                  Clientes
                </a>
              </div>
            </div>
            <div className="f-footer-contact-transactional f-footer-contact-transactional--zone">
              <div className="f-footer-contact-transactional-icon">
                <i className="f-icon fal fa-desktop"></i>
              </div>
              <div className="f-footer-contact-transactional-web">
                <a
                  className="f-footer-contact-transactional-web-form"
                  href={SupplierRedirect}
                  target="_blank"
                  onClick={() => datalayerFooter("Proveedores")}
                >
                  Proveedores
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="f-footer-v-separator-section" />
        <div className="f-footer-bottom">
          <div className="f-footer-logo-container">
            <img
              className="f-footer-logo"
              src={logo[3].file.url}
              alt="Logo Familia"
            />
          </div>
          <div className="f-footer-bottom-item f-footer-legal">
            {footerInformacionLegal}
          </div>
          <div className="f-footer-bottom-item f-footer-legal">
            {footerInformacionLegalDerechosReservados}
          </div>
          <a
            className="f-footer-bottom-item"
            href={siteUrl + "/" + footerRedireccionTerminosYCondiciones}
            onClick={() => datalayerFooter("Términos y condiciones")}
          >
            {footerTerminosYCondiciones}
          </a>
          <a
            className="f-footer-bottom-item"
            target="_blank"
            href={footerRedireccionPolticaDePrivacidad}
            onClick={() => datalayerFooter("Política de Protección de Datos")}
          >
            {footerPoliticaDePrivacidad}
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
